<template>
  <div class="agenda">
    <b-collapse
      v-for="(dia, index) in Object.values(dias)" :key="index"
      :open="diaActiveTab == index" @open="diaActiveTab = index"
      :aria-id="'contentIdForA11y5-' + index" class="card mb-4" animation="slide">

      <template #trigger="props" v-if="!isOneDayEvent">
        <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-' + index"
          :aria-expanded="props.open">
          <div class="card-header-title">
            <div :class="'circuloTitulo circulo_' + index">
              <div class="circuloTituloInner"></div>
            </div>
            <span class="has-text-weight-bold mr-2">Día {{ index + 1 }}</span>
            <span class="spacer"></span>
            <span>{{ FormatDate(dia[0].date) }}</span>
          </div>
          <a class="card-header-icon">
            <d-icon icon="FaChevronDown" size="is-small" :class="{ open: props.open }" />
          </a>
        </div>
      </template>
      <div class="card-content p-0">
        <div class="content">
          <div v-for="(evento, jndex) in sortedTimeSlots(dia)" :key="evento.id" class="card evento">
            <div class="card-content px-1 pb-5" :class="isOneDayEvent && jndex == 0 ? 'pt-2' : 'pt-5'">
              <div class="horaylugar mb-2 is-size-7" v-if="!evento.hideDate">
                  <time v-if="evento.startTime"
                    :datetime="`${evento.date.split('T')[0]} ${formatTime(evento.startTime)}`" class="hora">
                    <d-icon icon="FaClock" size="is-small" class="mr-1" />
                    <span>{{ formatTime(evento.startTime) }}</span>
                    <span v-if="evento.finishTime" class="ml-1">- {{ formatTime(evento.finishTime) }}</span>
                  </time>
                <span v-if="evento.place" class="lugar">
                  <d-icon icon="FaLocationDot" size="is-small" />
                  {{ evento.place }}
                </span>
              </div>

              <p v-if="evento.title" class="title is-size-5 has-text-black-bis pb-2 mb-0">{{ evento.title }}</p>
              <p v-if="evento.description" class="subtitle is-size-6 mt-0">{{ evento.description }}</p>

              <div v-for="speaker in evento.speakers" :key="speaker.id" class="orador mt-3">
                <div class="image" v-if="speaker.picture">
                  <img class="is-rounded has-background-grey-lighter" :src="speaker.picture.sizes.thumbnail.url"/>
                </div>
                <div>
                  <strong>{{ speaker.name }}</strong>
                  <div class="content">{{ speaker.resume }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dias: [],
      diaActiveTab: null,
      formatter: null,
    };
  },
  methods: {
    FormatDate(date) {  
      return this.formatter.format(new Date(date))
    },
    async FormatCalendar() {
      // Wait for events to be loaded
      while (!this.events) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      

      this.dias = this.events.reduce((acc, event) => {
        const date = event.date;

        // Agrupa eventos por fecha en un objeto
        acc[date] ? acc[date].push(event) : acc[date] = [event];

        return acc;
      }, {});

      const sortedDias = Object.keys(this.dias).sort((a, b) => new Date(a) - new Date(b));

      // Crear un nuevo objeto de días ordenado por fecha
      const diasOrdenados = {};
      sortedDias.forEach(date => {
        diasOrdenados[date] = this.dias[date];
      });

      this.dias = diasOrdenados;

      
      if(this.isOneDayEvent) this.diaActiveTab = 0

    },
    orderEventByTime(dia) {
      const eventosOrdenados = [...dia];
      // Ordena la copia de eventos por hora
      eventosOrdenados.sort((a, b) => {
        const horaInicioA = a.startTime.split('T')[1];
        const horaInicioB = b.startTime.split('T')[1];
        return horaInicioA.localeCompare(horaInicioB);
      });
      // Devuelve la copia ordenada de eventos
      return eventosOrdenados;
    },
    formatTime(fecha) {
      // Utiliza toLocaleTimeString para formatear la hora
      return new Date(fecha).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).replace('a. m.','AM').replace('p. m.','PM')
    },
    CheckActiveEvents() {
      let needsUpdate = false
      let now = new Date().getTime()
      this.entries.forEach(element => {
        let activo = now >= element.tsStart && now < element.tsFinish
        if (element.activo != activo) {
          needsUpdate = true // tengo que forzar porque sino no actualiza
        }
        element.activo = activo

        let yapaso = now > element.tsFinish
        if (element.yapaso != yapaso) {
          needsUpdate = true // tengo que forzar porque sino no actualiza
        }
        element.yapaso = yapaso

      });
      if (needsUpdate) {
        this.$forceUpdate();
      }
      setTimeout(() => {
        this.CheckActiveEvents()
      }, 10000);
    },
  },
  computed: {
    events() {
      return this.$store.state.event.events
    },
    isOneDayEvent() {
      return Object.values(this.dias).length == 1
    },
    sortedTimeSlots() {
      return (timeSlots) => {
        return timeSlots.slice().sort((a, b) => {
          const timeA = new Date(a.startTime).getHours() * 60 + new Date(a.startTime).getMinutes();
          const timeB = new Date(b.startTime).getHours() * 60 + new Date(b.startTime).getMinutes();
          return timeA - timeB;
        });
      };
    }
  },
  async mounted() {
    this.formatter = new Intl.DateTimeFormat(this.$i18n.locale, {day: "numeric", month: "long"})
    this.FormatCalendar()
  }
}
</script>

<style lang="scss" scoped src="./CalendarComponent.scss"></style>