<template>
  <div class="site">
    <main class="site-content is-flex is-flex-direction-column">
      <HeaderComponent :title="$t('event.calendar')"/>
      <b-tabs v-model="activeTab" :animated="false" expanded v-if="$store.state.event.events?.length > 0" class="calendar-tabs" :class="{ 'hide-labels': $store.state.event.eventsTab.hide }">
        <!-- TAB AGENDA -->
        <b-tab-item :label="$t('event.calendar')" class="tab-item">
          <CalendarComponent class="main-section p-4" />
        </b-tab-item>

        <!-- TAB EXTRA -->
        <b-tab-item v-if="!$store.state.event.eventsTab.hide" :label="extraTabLabel">
          <section class="section tab-item" >
            <json-rich-text :text="$store.state.event.eventsTab?.body ?? ''" />
          </section>
        </b-tab-item>
      </b-tabs>
      <section v-else class="p-4" style="margin-top: 41px">
        <div v-for="(_,i) in 3" :key="i" :class="{'mt-4': i > 0}">
          <b-skeleton size="is-large" height="40"/>
        </div>
      </section>
    </main>
    
  </div>
</template>

<script>
import CalendarComponent from "../components/CalendarComponent";
import HeaderComponent from "../components/HeaderComponent";
import JsonRichText from "@/components/JsonRichText.vue";

export default {
  components: {
    CalendarComponent,
    HeaderComponent,
    JsonRichText,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    GoToMenu() {
      this.$router.push("/");
    },
  },
  computed: {
    backgroundImage() {
      if (this.$store.state.setup.headerBackground) return `background-image: url(${this.$store.state.setup.headerBackground.url})`
      return null
    },
    extraTabLabel() {
      const title = this.$store.state.event.eventsTab?.title;
      return title && title.trim().length > 0 ? title : 'Info';
    },
  },
  async mounted() {
    this.$socket.client.emit("player:JoinSpace", { spaceSlug: "calendar" },() => {})
    await this.$store.dispatch("event/LoadCalendarEvents");
    this.$store.commit("StopLoading");
  },
  beforeMount() {
    if(!this.$store.state.setup.event.calendar?.enabled) {
      this.$router.push('/')
    }
  },
};
</script>


<style lang="scss" scoped>
.calendar-tabs {
  height: calc(100% - 86px);
}

.hide-labels {
  :deep(nav.tabs) {
    display: none;
  }
}
:deep(nav.tabs) {
  height: 41px;
}
:deep(.tab-content) {
  height: calc(100% - 41px) !important;
  overflow: hidden;
  padding: 0;
}

.tab-item {
  overflow-y: auto;
  height: 100%;

}
</style>